import React from 'react';

const UnderConstructionPage = () => {
  const pageStyle = {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#000000', // Black background
    color: '#FFD700', // Gold text
    fontFamily: '"Playfair Display", serif', // Primary font
    textAlign: 'center',
    padding: '20px'
  };

  const headingStyle = {
    fontSize: '3rem',
    marginBottom: '20px'
  };

  const textStyle = {
    fontSize: '1.5rem',
    color: '#FFFFFF' // White text for contrast
  };

  return (
    <div style={pageStyle}>
      <h1 style={headingStyle}>Under Construction</h1>
      <p style={textStyle}>We're working hard to bring you the best experience!</p>
      <p style={textStyle}>Please check back soon.</p>
    </div>
  );
};

export default UnderConstructionPage;