import React from 'react';
import './SmallLotteryBall.css';

const SmallLotteryBall = ({ number }) => {
  return (
    <div className={'small-lottery-ball'}>
      {number}
    </div>
  );
};

export default SmallLotteryBall;