import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import './App.css';

// Import custom components
import Header from './components/Header';
import Footer from './components/Footer';
import Jueteng from './pages/NumbersGameApp';
import GamesPage from './pages/GamesPage';
// import PromotionsPage from './pages/PromotionsPage';
import UnderConstruction from './pages/UnderConstruction';
import { createTheme } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material/styles';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FFD700', // Gold
    },
    secondary: {
      main: '#ffbf00', // Black
    },
    error: {
      main: '#DC143C', // Red Accent
    },
    success: {
      main: '#008000', // Green Accent
    },
    background: {
      default: '#0b0000', // Black
      paper: '#454545', // Dark Gray
    },
    text: {
      primary: '#FFFFFF', // White
      secondary: '#FFD700', // 
    },
  },
  typography: {
    fontFamily: [
      'Roboto', // Replace with your secondary font
      '"Open Sans"', // Replace with your secondary font
      'sans-serif',
    ].join(','),
    h1: {
      fontFamily: 'Playfair Display, serif', // Replace with your primary font
    },
    // Add other typography styles as needed
  },
  // Add any other theme customization you need
});


function App() {
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <div className="App">
          <Header />
          <main className="content">
            <Routes>
              <Route path="/" element={<GamesPage/>} />
              <Route path="/jueteng" element={<Jueteng/>} />
              <Route path="*" element={<UnderConstruction/>} /> 
            </Routes>
          </main>
          <Footer />
        </div>
      </Router>
    </ThemeProvider>
  );
}

export default App;
