import React, { useState } from 'react';
import LotteryBall from '../components/LotteryBall';
import CurrentBetsTable from '../components/CurrentBetsTable';
import ConfirmBetsTable from '../components/ConfirmBetsTable';
import { Slider, Typography, Checkbox, FormControlLabel, InputAdornment, TextField, Box, useTheme, Card, CardActionArea, CardContent, Grid, styled } from '@mui/material';
import './NumbersGameApp.css';

const formatBetDetails = (bet) => {
  const insuranceAmount = bet.insurance ? bet.betAmount * 0.5 : 0;
  if (bet.insurance) {
    return `Php ${bet.betAmount} + Php ${insuranceAmount} (Insurance) = Php ${bet.betAmount + insuranceAmount}`;
  } else {
    return `Php ${bet.betAmount}`;
  }
};

const BetPlacement = ({ onAddBet, onProceed, bets}) => {
  const [selectedNumbers, setSelectedNumbers] = useState([]);
  const [betAmount, setBetAmount] = useState(1);
  const [insurance, setInsurance] = useState(false);

  const toggleNumberSelection = (num) => {
    setSelectedNumbers(prevSelected => {
      const count = prevSelected.filter(n => n === num).length;

      // If the selected number is not in the array, add it
      if (count === 0) {
        if (prevSelected.length < 2) {
          return [...prevSelected, num];
        } else {
          // Replace the first number with the new selection
          return [prevSelected[1], num];
        }
      }

      // If the selected number is in the array once
      if (count === 1) {
        // If total numbers are two, replace the other number
        if (prevSelected.length === 2) {
          return [num, num];
        } else {
          return [...prevSelected, num];
        }
      }

      // If the selected number is already in the array twice, keep as is
      return prevSelected;
    });
  };

  const handleAddBet = () => {
    if (selectedNumbers.length === 2) {
      onAddBet({ num1: selectedNumbers[0], num2: selectedNumbers[1], betAmount, insurance });
      setSelectedNumbers([]);
      setBetAmount(1);
      setInsurance(false);
    } else {
      alert('Please select two numbers');
    }
  };

  const handleBetAmountChange = (event, newValue) => {
    setBetAmount(newValue);
  };

  const handleInputChange = (event) => {
    const value = Math.min(Math.max(Number(event.target.value), 1), 1000);
    setBetAmount(value);
  };

  return (
    <div style={{ color: '#FFD700' }}>
      <h2>Place Your Bets</h2>
      <div className="number-grid">
        {[...Array(37).keys()].map(num => {
          const number = num + 1;
          const count = selectedNumbers.filter(n => n === number).length;

          return (
            <LotteryBall
              key={number}
              number={number}
              count={count}
              onClick={() => toggleNumberSelection(number)}
            />
          );
        })}
      </div>
      <Box sx={{ color: "#FFD700" }}>
        <Typography gutterBottom>Bet Amount</Typography>
        <Box>
          <Slider
            value={betAmount}
            onChange={handleBetAmountChange}
            aria-label="Bet Amount"
            valueLabelDisplay="auto"
            min={1}
            max={1000}
          />
          <TextField
            value={betAmount}
            onChange={handleInputChange}
            InputProps={{
              startAdornment: <InputAdornment position="start">Php</InputAdornment>,
              style: { border: '2px solid #FFD700' },
              inputProps: { min: 1, max: 1000, type: 'number', 'aria-labelledby': 'input-slider' },
            }}
            variant="outlined"
            sx={{ width: '150px', mt: '10px' }}
          />
        </Box>
      </Box>
      <Box sx={{ margin: '10px 0' }}>
        <FormControlLabel
          control={<Checkbox checked={insurance} onChange={() => setInsurance(!insurance)} color="primary" />}
          label="Add Insurance (50% of bet)"
        />
      </Box>
      <button onClick={handleAddBet}>Add Bet</button>
      {bets.length > 0 ? <CurrentBetsTable bets={bets} formatBetDetails={formatBetDetails}/> : <h2 style={{ fontSize: '1.5rem', textAlign: 'center', marginTop: '20px' }}>No bets yet, start betting now!</h2>}
      {/* Proceed to Confirmation Button */}
      <div style={{ margin: '10px 0' }}>
        <button onClick={onProceed}>Proceed to Confirmation</button>
      </div>
    </div>
  );
};

const BetConfirmation = ({ bets, onConfirm, onRemoveBet, onReturnToBetting }) => {
  const [email, setEmail] = useState('');
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('Gcash');
  const theme = useTheme();

  const StyledCard = styled(Card)(({ selected, available }) => ({
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    border: selected ? `3px solid ${theme.palette.success.main}` : `1px solid ${theme.palette.primary.main}`,
    boxShadow: selected ? `0 0 15px ${theme.palette.success.main}` : `0 4px 8px 0 ${theme.palette.primary.main}`,
    opacity: available ? 1 : 0.5,
    '&:hover': {
      boxShadow: available ? (selected ? `0 0 15px ${theme.palette.success.main}` : `0 8px 16px 0 ${theme.palette.primary.main}`) : 'none',
      cursor: available ? 'pointer' : 'default',
    },
  }));

  const PaymentOptions = () => {
    const availableMethods = { 'Gcash': true, 'Crypto': false, 'Bank': false };

    return (
      <Grid container spacing={2} justifyContent="center" sx={{ padding: 2 }}>
        {Object.entries(availableMethods).map(([method, available]) => (
          <Grid item xs={12} sm={6} md={4} lg={3} key={method}>
            <StyledCard selected={selectedPaymentMethod === method} available={available} onClick={() => available && setSelectedPaymentMethod(method)}>
              <CardActionArea disabled={!available}>
                <CardContent sx={{ textAlign: 'center' }}>
                  <img 
                    src={`/${method.toLowerCase()}.png`}
                    alt={method}
                    style={{ height: '100px', width: '100px', margin: '20px auto' }}
                  />
                  <Typography gutterBottom variant="h5" component="div" sx={{ fontFamily: theme.typography.h1.fontFamily }}>
                    {method}
                  </Typography>
                  {!available && <Typography variant="body2" sx={{ color: theme.palette.error.main, fontFamily: theme.typography.fontFamily }}>Coming Soon</Typography>}
                </CardContent>
              </CardActionArea>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    );
  };

  const totalAmount = bets.reduce((acc, bet) => acc + bet.betAmount + (bet.insurance ? bet.betAmount * 0.5 : 0), 0);
  const isValidEmail = email => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleConfirm = () => {
    if (!isValidEmail(email)) {
      alert('Please enter a valid email address.');
      return;
    }
    onConfirm(email, selectedPaymentMethod, totalAmount);
  };

  const canRemoveBet = bets.length > 1;

  return (
    <div>
      <h2>Confirm Your Bets</h2>
      <ConfirmBetsTable bets={bets} onRemoveBet={canRemoveBet ? onRemoveBet : null} formatBetDetails={formatBetDetails} />

      <Box sx={{ margin: '20px 0' }}>

        <Typography variant='h4'>
          Total Amount: Php {totalAmount}
        </Typography>
        <TextField
          label="Email"
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          fullWidth
          required
          variant='outlined'
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: '#FFD700',
              },
              '&:hover fieldset': {
                borderColor: '#DAA520',
              },
              '&.Mui-focused fieldset': {
                borderColor: '#FFD700',
              },
            },
          }}
          mt="20px"
        />
        <PaymentOptions />
      </Box>
      <button onClick={onReturnToBetting}>Return to Betting Page</button>
      <button onClick={handleConfirm}>Confirm Bet</button>
    </div>
  );
};

const BetFinalization = ({ email, paymentMethod, totalAmount, bets }) => {
  return (
    <div style={{ textAlign: 'center', padding: '20px' }}>
      <h2 style={{ fontSize: '2rem', marginBottom: '20px' }}>Thank You for Your Bet</h2>
      <div style={{ marginBottom: '20px' }}>
        <Typography variant="h4">
        Email: {email}
        </Typography>
        <h4 style={{ fontSize: '2rem', marginBottom: '20px' }}>Payment Method: {paymentMethod}</h4>
        <h3 style={{ fontSize: '2rem', marginBottom: '20px' }}>Total Amount: Php {totalAmount}</h3>
      </div>
      <div style={{ border: '2px solid #FFD700', padding: '15px', borderRadius: '10px' }}>
        <h2 style={{ fontSize: '2rem', marginBottom: '20px' }}>Your Bets</h2>
        <ConfirmBetsTable bets={bets} formatBetDetails={formatBetDetails} />
      </div>
      <h3 style={{ fontSize: '2rem', marginTop: '20px' }}>Upon confirmation of payment, please wait for an email confirmation for the drawing of numbers.</h3>
    </div>
  );
};

const NumbersGameApp = () => {
  const [bets, setBets] = useState([]);
  const [currentPage, setCurrentPage] = useState('placement');

  const handleAddBet = (bet) => setBets([...bets, bet]);
  const handleProceed = () => setCurrentPage('confirmation');
  const handleRemoveBet = (index) => setBets(bets.filter((_, i) => i !== index));
  const [confirmDetails, setConfirmDetails] = useState({});

  const handleConfirm = (email, paymentMethod, totalAmount) => {
    setConfirmDetails({ email, paymentMethod, totalAmount });
    setCurrentPage('finalization');
    // setBets([]);
  };
  const handleReturnToBetting = () => {
    setCurrentPage('placement');
  };

  return (
    <div className="numbers-game-app">
      {currentPage === 'placement' ? (
        <BetPlacement onAddBet={handleAddBet} onProceed={handleProceed} bets={bets} />
      ) : currentPage === 'confirmation' ? (
        <BetConfirmation bets={bets} onConfirm={handleConfirm} onRemoveBet={handleRemoveBet} onReturnToBetting={handleReturnToBetting} />
      ) : (
        <BetFinalization {...confirmDetails} bets={bets} />
      )}
    </div>
  );

};

export default NumbersGameApp;