import React, {useState} from 'react';
import { NavLink, Navigate} from 'react-router-dom';
import './Header.css'; // Make sure to create a Header.css file
import MenuIcon from '@mui/icons-material/Menu'; // Import Menu icon from Material-UI
import IconButton from '@mui/material/IconButton';

const Header = () => {
  const [navVisible, setNavVisible] = useState(true);

  const toggleNav = () => {
    setNavVisible(!navVisible);
  };

  const handleLogoClick = () => {
    Navigate('/')
  }

  return (
    <header className="header">
      <div className="logo-and-nav">
        <img src="./logo512.png" alt="Logo" className="logo" onClick={handleLogoClick} />
        <IconButton className="menu-button" onClick={toggleNav}>
          <MenuIcon />
        </IconButton>
        <nav className={`navigation ${navVisible ? '' : 'hidden'}`}>
          <NavLink to="/" activeClassName="active">Games</NavLink>
          <NavLink to="/referrals" activeClassName="active">Referrals</NavLink>
          <NavLink to="/promotions" activeClassName="active">Promotions</NavLink>
        </nav>
      </div>
    </header>
  );
};

export default Header;