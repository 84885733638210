import React from 'react';
import GameTile from './GameTile'; // Import the GameTile component
import './GamesPage.css'; // Import the CSS for the GamesPage

const GamesPage = () => {
  // Placeholder data for games, including 'isComingSoon' status
  const games = [
    { id: 1, title: "Jueteng", description: "Bet on two-number combinations for up to 400x payouts!", image: "/jueteng.png", isComingSoon: false, playUrl: '/jueteng'},
    { id: 2, title: "3 Card Poker", description: "Make the best three-card hand to beat the dealer!", image: "/poker.png", isComingSoon: true},
    { id: 3, title: "Plinko", description: "Drop chips down to win cash prizes!", image: "/plinko.png", isComingSoon: true }, // Marked as coming soon
  ];

  return (
    <div className="games-page">
      <div className="game-grid">
        {games.map(game => (
          <GameTile 
            key={game.id}
            title={game.title}
            description={game.description}
            image={game.image}
            isComingSoon={game.isComingSoon}
            playUrl={game.playUrl}
          />
        ))}
      </div>
    </div>
  );
};

export default GamesPage;
