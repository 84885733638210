import React from 'react';
import './GameTile.css'; // Import the CSS for GameTile


const GameTile = ({ title, description, image, isComingSoon, playUrl }) => {
    const handlePlayClick = () => {
        // Redirect to the game URL
        window.location.href = playUrl;
    };

  return (
    <div className="game-tile">
      <img src={image} alt={title} className="game-image"/>
      <h2 className="game-title">{title}</h2>
      <p className="game-description">{description}</p>
      {isComingSoon ? (
        <button className="coming-soon-btn" disabled>Coming Soon</button>
      ) : (
        <button className="play-now-btn" onClick={handlePlayClick}>Play Now</button>
      )}
    </div>
  );
};

export default GameTile;