import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import SmallLotteryBall from './SmallLotteryBall';


function CurrentBetsTable({ bets, formatBetDetails }) {
  return (
    <TableContainer component={Paper} style={{ backgroundColor: 'transparent' }}>
      <Table aria-label="current bets table">
        <TableHead>
          <TableRow>
            <TableCell>Numbers</TableCell>
            <TableCell align="right">Amount</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {bets.map((bet, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <SmallLotteryBall key={bet.num1} number={bet.num1}/> 
                <SmallLotteryBall key={bet.num2} number={bet.num2}/> 
              </TableCell>
              <TableCell align="right" style={{ fontSize: '1.2rem' }}>
              <Typography variant='h4'>
                {formatBetDetails(bet)}
              </Typography>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default CurrentBetsTable;