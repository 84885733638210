import React from 'react';
import './LotteryBall.css'; // Import the CSS file for styling

const LotteryBall = ({ number, count, onClick }) => {
  const ballStyle = count > 0 ? "lottery-ball selected" : "lottery-ball";

  return (
    <div className={ballStyle} onClick={onClick}>
      {number}
      {count > 1 && <span className="count-badge">{count}</span>}
    </div>
  );
};

export default LotteryBall;