import React from 'react';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Typography } from '@mui/material';
import SmallLotteryBall from './SmallLotteryBall'; // Adjust the import path as necessary

function ConfirmBetsTable({ bets, onRemoveBet, formatBetDetails }) {
  return (
    <TableContainer component={Paper} style={{ backgroundColor: 'transparent' }}>
      <Table aria-label="confirm bets table">
        <TableHead>
          <TableRow>
            <TableCell>Numbers</TableCell>
            <TableCell align="right">Amount</TableCell>
            {onRemoveBet && (
            <TableCell align="right">Action</TableCell>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          {bets.map((bet, index) => (
            <TableRow key={index}>
              <TableCell component="th" scope="row">
                <SmallLotteryBall number={bet.num1}/> 
                <SmallLotteryBall number={bet.num2}/> 
              </TableCell>
              <TableCell align="right">
                <Typography variant='h4'>
                  {formatBetDetails(bet)}
                </Typography>
              </TableCell>
              {onRemoveBet && (
                <TableCell align="right">
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => onRemoveBet(index)}
                  >
                    Remove
                  </Button>
                </TableCell>
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}

export default ConfirmBetsTable;